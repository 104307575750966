<template>
  <div class="col-12 g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <div class="form-group">
              <h5 for="">بحث</h5>
              <input type="text"
                class="form-control" v-model="q" placeholder="ابحث هنا..." @keyup="getStudents()" @change="getStudents()">
            </div>
            <table class="table table-sm table-hover table-bordered table-striped">
                <thead>
                    <th>
                        الاسم
                    </th>
                    <th>
                        الهوية
                    </th>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الرصيد
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="student in students" :key="student._id">
                        <td>
                            <a href="javascript:;" @click="current2 = student; getHistory(student.number)" v-b-modal.modal-2>{{ student.name }}</a>
                        </td>
                        <td>
                            {{ student.number }}
                        </td>
                        <td>
                            {{ student.phone }}
                        </td>
                        <td>
                            {{ student.balance }} ريال
                        </td>
                        <td>
                            <b-dropdown
                                id="dropdown-1"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                text="خيارات"
                                variant="primary"
                                size="sm"
                            >
                                <b-dropdown-item @click="current2 = student; getHistory(student.number)" v-b-modal.modal-2>
                                <i class="fa fa-pie-chart"></i>
                                نظرة عامة
                                </b-dropdown-item>
                                <b-dropdown-item v-b-modal.modal-1 @click="current = student">
                                <i class="fa fa-edit"></i>
                                تعديل
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteUser(student._id)">
                                <i class="fa fa-trash"></i>
                                حذف
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="modal-1" title="تعديل المستخدم" hide-footer>
        <div class="form-group">
            <label for="">
            الاسم
            </label>
            <input type="text"
            class="form-control" v-model="current.name">
        </div>
        <div class="form-group">
            <label for="">
            رقم الهوية
            </label>
            <input type="text"
            class="form-control" v-model="current.number">
        </div>
        <div class="form-group">
            <label for="">
            الجوال
            </label>
            <input type="text"
            class="form-control" v-model="current.phone">
        </div>
        <div class="form-group">
            <label for="">
            عدد مرات السحب في اليوم
            </label>
            <input type="number"
            class="form-control" v-model="current.perday">
        </div>
        <div class="form-group">
            <label for="">
            المبلغ المسموح به في كل مرة
            </label>
            <input type="number"
            class="form-control" v-model="current.amount">
        </div>
                <div class="form-group">
                  <label for="">
                    ملاحظات (تظهر في بطاقة المصروف)
                  </label>
                  <input type="text"
                    class="form-control" v-model="current.notes">
                </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="edit()">حفظ الطالب</button>
        </div>
    </b-modal>
    <b-modal id="modal-2" title="الملف الشخصي" hide-footer size="lg">
        <div v-if="current2.name">
            <div class="col-12 text-center">
                <h1>متبقي <strong class="text-danger">{{ current2.balance }}</strong> ريال </h1>
                <h3>
                    {{ current2.name }}
                </h3>
                <p class="text-muted">{{ current2.number }} &mdash; {{ current2.phone }}</p><br>
            </div>
            <div class="col-12 text-center g" v-if="loading">
                <img :src="require('@/assets/images/loading.svg')" alt="">
            </div>
            <div class="col-12" v-if="!loading">
                <div class="strike">
                    <span>سجل شحن الرصيد</span>
                </div>
                <div class="col-12 table-responsive g">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                المبلغ
                            </th>
                            <th>
                                الرصيد قبل
                            </th>
                            <th>
                                الرصيد بعد
                            </th>
                            <th>
                                ملاحظات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="c in charges" :key="c._id">
                                <td>{{ c.date }}</td>
                                <td>{{ c.amount }}</td>
                                <td>{{ c.balance_before }}</td>
                                <td>{{ c.balance_after }}</td>
                                <td>{{ c.notes }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="strike">
                    <span>سجل السحب</span>
                </div>
                <div class="col-12 table-responsive g">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                المبلغ
                            </th>
                            <th>
                                الرصيد قبل
                            </th>
                            <th>
                                الرصيد بعد
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="c in history" :key="c._id">
                                <td>{{ c.date }}</td>
                                <td>{{ c.amount }}</td>
                                <td>{{ c.balance_before }}</td>
                                <td>{{ c.balance_after }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
    data(){
        return {
            students: [],
            loading: false,
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            q: null,
            current: {},
            current2: {},
            charges: [],
            history: []
        }
    },
    created(){
        var g = this;
        g.getStudents()
    },
    methods: {
        getHistory(){
            var g = this;
            this.loading = true
            $.post(api + '/user/students/history', {
                jwt: this.jwt,
                number: this.current2.number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.history = r.response.history
                    g.charges = r.response.charges
                }
            }).fail(function(){
                g.loading = false
                alert("حدث مشكلة في الاتصال")
            })
        },
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.jwt,
                q: g.q
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        },
        edit(){
            var g = this;
             $.post(api + '/user/students/edit', {
                jwt: this.jwt,
                id: g.current._id,
                name: g.current.name,
                phone: g.current.phone,
                number: g.current.number,
                perday: g.current.perday,
                amount: g.current.amount,
                notes: g.current.notes
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#modal-1___BV_modal_header_ > button").click()
                    g.getStudents()
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        },
        deleteUser(d){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/user/students/delete', {
                    jwt: this.jwt,
                    id: d
                }).then(function(r){
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getStudents()
                    }
                }).fail(function(){
                    alert("حدث مشكلة في الاتصال")
                })
            }
        }
    }
}
</script>

<style scoped>
.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap; 
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #eee;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}
</style>